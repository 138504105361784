<template>
  <v-theme-provider>
    <base-section
      id="news"
      space="40"
    >
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <base-section-heading title="Berita" />
            <base-body space="0">
               <v-container fluid>
                <v-row dense>
                  <v-col
                  xs="12"
                  md="12"
                  cols="12">
                  <v-select
                      v-model="select"
                      :items="category"
                      label="Pilih Kategori"
                      dense
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col
                    v-for="data in filtered"
                    :key="data.web_news_id"
                    md="4"
                    xs="12"
                    cols="12"
                  >
                    <v-card @click="Detail(data.web_news_id)">
                      <v-img
                        :src="data.web_news_src_url"
                        class="white--text align-end"
                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        height="400px"
                        :alt="data.web_news_name"
                      >
                        <v-card-title>{{FormatDateFullOnlyDate(data.web_news_cdate)}} <v-spacer></v-spacer>{{data.web_news_category}} </v-card-title>
                      </v-img>
                      <v-card-actions>
                        {{ data.web_news_name }}
                        <v-spacer></v-spacer>
                        <v-btn icon @click="Share(data.web_news_id)">
                          <v-icon>mdi-whatsapp</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </base-body>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionNews',
    data: () => ({
            select: 'Semua',
            posts: [],
            category: [
              'Semua',
              'berita',
              'tips',
            ],
        }),
    created () {
        this.List()
    },
    computed: {
      filtered () {
        const self = this;
        if (self.select === 'Semua') {
          return self.posts;
        } else {
          return self.posts.filter(function(data) {
            return self.select === data.web_news_category;
          });
        }
      }
    },
    methods: {
        List () {
          this.posts = [
                        {'web_news_id':'1','web_news_name':'Berita 1','web_news_src_url':''},
                        {'web_news_id':'2','web_news_name':'Berita 2','web_news_src_url':''},
                        {'web_news_id':'2','web_news_name':'Berita 3','web_news_src_url':''},
                        {'web_news_id':'2','web_news_name':'Berita 4','web_news_src_url':''},
                        {'web_news_id':'2','web_news_name':'Berita 5','web_news_src_url':''},
                        {'web_news_id':'2','web_news_name':'Berita 6','web_news_src_url':''},
                        ]
          // this.loading  = true
          // let formdata = {
          //       web_news_id: '',
          //       web_news_type: 'news',
          //       web_news_actived: 'Y',
          //       order_by: 'web_news_cdate',
          //       order_type: 'DESC',
          // }
          // let param      = this.$functions.ParamPOST(formdata)
          // this.$axios.post(this.$functions.UrlPOST('apiWebNewsList'),param,{
          // headers: {
          //     'Content-Type': 'text/plain; charset=ISO-8859-1'
          // }
          // })
          // .then(response => {
          //     this.posts = response.data
          //     this.loading  = false
          // })
          // .catch(e => {
          //     alert(e)
          //     this.loading  = false
          // })
        },
        MaxLength (content, max) {
          let returns = content
          if (content.length >= max) {
            returns = content.substr(0,max) + '... '
          }
          return returns
        },
        Share (id) {
          let paramurl = this.$functions.AppURL() +'/berita-detail/'+id
          window.open('https://wa.me/?text='+encodeURIComponent(paramurl))
        },
        Detail (url) {
          this.$router.push({name: 'berita-detail', params: { url: url }})
        },
        FormatDateFullOnlyDate (date) {
          return this.$functions.FormatDateFullOnlyDate(date)
        },
    }
  }
</script>
